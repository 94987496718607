import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import deLocale from 'date-fns/locale/de';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, useField } from 'react-final-form';
import {
  LocalizationProvider,
  DesktopDatePicker as MuiDatePicker
} from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';

import Label from './Label';

import {
  compose,
  date,
  maxDate,
  minDate,
  required
} from '../utils/form/validators';
import { isDate, isInPast } from '../utils/date';

const useStyles = makeStyles(theme => ({
  datePicker: {
    width: '12.5em',
    '& .MuiOutlinedInput-input, .MuiOutlinedInput-multiline': {
      padding: '.625em .75em',
      fontSize: theme.font?.size?.input,
      '&::placeholder': {
        color: theme.color.text.light,
        opacity: 0.9,
        textTransform: 'uppercase'
      }
    },
    '& .MuiOutlinedInput-inputMultiline': {
      padding: '0'
    },
    '& .MuiInputLabel-formControl': {
      position: 'initial'
    },
    '& .MuiFormHelperText-root': {
      margin: 0
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: theme.color.primary.main
    },
    ...(theme.components?.textField?.root
      ? theme.components?.textField?.root
      : {})
  }
}));

const DatePicker = props => {
  const classes = useStyles(props);
  // const [open, setOpen] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const field = useField(props.id);

  function handleChange(dt) {
    handleFocus();
    field.input.onChange(dt);
    handleBlur();

    if (props.onChange) props.onChange(dt);
  }

  // function handlePickerOpen() {
  //   if (!props.disabled) {
  //     setOpen(true);
  //   }
  // }

  // function handlePickerClose() {
  //   setOpen(false);
  // }

  function handleBlur() {
    field.input.onBlur();

    if (props.onBlur) props.onBlur();
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);

    validators.push(date);

    if (props.min) validators.push(minDate(props.min, props.minMessage));
    if (props.max) validators.push(maxDate(props.max, props.maxMessage));

    return validators;
  }
  useEffect(() => {
    if (
      !props.disablePastWarning &&
      isDate(field.input.value) &&
      isInPast(field.input.value, { onlyDay: true })
    ) {
      setHelperText(props.pastWarning);
    } else {
      setHelperText(null);
    }
  }, [field.input.value]);

  return (
    <Field
      name={props.id}
      validate={compose(getValidators())}
      validateFields={props.validateFields || []}
    >
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText = meta.touched ? meta.error || meta.submitError : '';

        return (
          <Box className={classes.datePicker}>
            <Label
              for={props.id}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
              {...props.labelProps}
            >
              {props.label}
            </Label>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={deLocale}
            >
              <MuiDatePicker
                id={props.id}
                name={props.id}
                disabled={props.disabled}
                value={props.value || input.value || props.defaultValue}
                required={props.required && !props.requiredWithoutAsterisk}
                // TBD: intl
                // onClick={handlePickerOpen}
                // onClose={handlePickerClose}
                // open={open}
                error={props.error || error}
                componentsProps={{
                  switchViewButton: {
                    title: 'Kaleneransicht verändern (Monat/Jahr)',
                    'aria-label': 'Kaleneransicht verändern (Monat/Jahr)'
                  },
                  leftArrowButton: {
                    title: 'Zum vorherigen Monat wechseln',
                    'aria-label': 'Zum vorherigen Monat wechseln'
                  },
                  rightArrowButton: {
                    title: 'Zum nachfolgenden Monat wechseln',
                    'aria-label': 'Zum nachfolgenden Monat wechseln'
                  }
                }}
                renderInput={props => (
                  <TextField
                    {...props}
                    id={props.id}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    error={props.error || error}
                    inputProps={{
                      ...props.inputProps,
                      placeholder: 'TT.MM.JJJJ'
                    }}
                    helperText={
                      props.errorText ||
                      errorText ||
                      props.helperText ||
                      helperText
                    }
                    FormHelperTextProps={{
                      'aria-label':
                        props.errorText ||
                        errorText ||
                        props.helperText ||
                        helperText
                    }}
                  />
                )}
                // label={label}
                helperText={
                  props.errorText || errorText || props.helperText || helperText
                }
                InputProps={{
                  id: props.id,
                  notched: false
                }}
                InputLabelProps={{
                  shrink: true,
                  variant: 'standard',
                  disableAnimation: true,
                  required: props.required && !props.requiredWithoutAsterisk
                }}
                variant="inline"
                inputVariant="outlined"
                OpenPickerButtonProps={{
                  'aria-label': 'Datum auswählen'
                }}
                onChange={handleChange}
                minDate={props.min ? props.min : undefined}
                maxDate={props.max ? props.max : undefined}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                PopoverProps={{
                  disableAutoFocus: true,
                  disableEnforceFocus: true
                }}
                autoOk
              />
            </LocalizationProvider>
          </Box>
        );
      }}
    </Field>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  disablePastWarning: PropTypes.bool,
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  locale: PropTypes.string,
  max: PropTypes.instanceOf(Date),
  maxMessage: PropTypes.func,
  minMessage: PropTypes.func,
  min: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
  errorText: PropTypes.string,
  pastWarning: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  position: PropTypes.oneOf(['bottom', 'bottom right', 'bottom mouse']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.instanceOf(Date)
};

DatePicker.defaultProps = {
  disabled: false,
  disablePastWarning: false,
  error: false,
  label: null,
  labelProps: {},
  locale: 'en-EN',
  max: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  min: undefined,
  minMessage: undefined,
  maxMessage: undefined,
  defaultValue: null,
  errorText: null,
  pastWarning: 'Das ausgewählte Datum liegt in der Vergangenheit.',
  helperText: null,
  onChange: () => {},
  onClose: () => {},
  onOpen: () => {},
  onFocus: () => {},
  onBlur: () => {},
  position: 'bottom',
  size: 'medium',
  value: null
};
