import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import deLocale from 'date-fns/locale/de';
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, useField } from 'react-final-form';
import {
  LocalizationProvider,
  PickersDay,
  DesktopDatePicker as MuiDatePicker
} from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import Label from './Label';

import {
  compose,
  date,
  maxDate,
  minDate,
  required
} from '../utils/form/validators';
import { isDate, isInPast } from '../utils/date';

const dateFnsOptions = { locale: deLocale };

const useStyles = makeStyles(theme => ({
  datePicker: {
    width: '10em',
    '& .MuiOutlinedInput-input, .MuiOutlinedInput-multiline': {
      padding: '.625em .75em',
      color: props =>
        props.disabled ? theme.color.text.light : theme.color.text.main,
      fontSize: theme.font?.size?.input
    },
    '& .MuiOutlinedInput-inputMultiline': {
      padding: '0'
    },
    '& .MuiFormHelperText-root': {
      margin: 0
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: theme.color.primary.main
    },
    ...(theme.components?.textField?.root
      ? theme.components?.textField?.root
      : {})
  }
}));

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})(({ theme, dayIsBetween, isFirstDay, isLastDay, selected }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.color.primary.main,
    color: theme.color.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: `${theme.color.primary.main} !important`
    },
    ...(theme.components?.weekPicker?.dayBetween
      ? theme.components?.weekPicker?.dayBetween
      : {})
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    ...(theme.components?.weekPicker?.firstDay
      ? theme.components?.weekPicker?.firstDay
      : {})
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    ...(theme.components?.weekPicker?.lastDay
      ? theme.components?.weekPicker?.lastDay
      : {})
  }),
  ...(selected && {
    backgroundColor: `${theme.color.primary.main} !important`
  }),
  ...(theme.components?.weekPicker?.day
    ? theme.components?.weekPicker?.day
    : {})
}));

export default function CustomDay(props) {
  const classes = useStyles(props);
  const field = useField(props.id);
  const [helperText, setHelperText] = useState(false);
  const value = props.value || field.input.value || props.defaultValue;

  function handleChange(dt) {
    field.input.onChange(dt);

    if (props.onChange) props.onChange(dt);
  }

  function handleBlur() {
    field.input.onBlur();

    if (props.onBlur) props.onBlur();
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [date];

    setTimeout(() => {
      if (props.required) validators.push(required);
      if (props.min) validators.push(minDate(props.min));
      if (props.max) validators.push(maxDate(props.max));
    });

    return validators;
  }

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(value, dateFnsOptions);
    const end = endOfWeek(value, dateFnsOptions);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  useEffect(() => {
    if (
      !props.disablePastWarning &&
      isDate(field.input.value) &&
      isInPast(field.input.value, { onlyDay: true })
    ) {
      setHelperText(props.pastWarning);
    } else {
      setHelperText(null);
    }
  }, [field.input.value]);

  return (
    <Field
      name={props.id}
      validate={compose(getValidators())}
      validateFields={props.validateFields || []}
    >
      {({ meta, input }) => {
        const error = (meta.error || meta.submitError) && meta.touched;
        const errorText = meta.touched ? meta.error || meta.submitError : '';

        return (
          <Box className={classes.datePicker}>
            <Label
              for={props.id}
              disabled={props.disabled}
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
              {...props.labelProps}
            >
              {props.label}
            </Label>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={deLocale}
            >
              <Box className={classes.datePicker}>
                <MuiDatePicker
                  id={props.id}
                  disabled={props.disabled}
                  value={value}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  renderDay={renderWeekPickerDay}
                  componentsProps={{
                    switchViewButton: {
                      title: 'Kaleneransicht verändern (Monat/Jahr)',
                      'aria-label': 'Kaleneransicht verändern (Monat/Jahr)'
                    },
                    leftArrowButton: {
                      title: 'Zum vorherigen Monat wechseln',
                      'aria-label': 'Zum vorherigen Monat wechseln'
                    },
                    rightArrowButton: {
                      title: 'Zum nachfolgenden Monat wechseln',
                      'aria-label': 'Zum nachfolgenden Monat wechseln'
                    }
                  }}
                  OpenPickerButtonProps={{
                    'aria-label': 'Kalenderwoche auswählen'
                  }}
                  error={props.error || error}
                  renderInput={params => (
                    <TextField
                      {...params}
                      id={props.id}
                      helperText={
                        props.errorText ||
                        errorText ||
                        props.helperText ||
                        helperText
                      }
                      FormHelperTextProps={{
                        'aria-label':
                          props.errorText ||
                          errorText ||
                          props.helperText ||
                          helperText
                      }}
                    />
                  )}
                  required={props.required && !props.requiredWithoutAsterisk}
                  inputFormat="'KW'ww yyyy"
                  mask="KW__"
                />
              </Box>
            </LocalizationProvider>
          </Box>
        );
      }}
    </Field>
  );
}

CustomDay.propTypes = {
  defaultValue: PropTypes.instanceOf(Date),
  disablePastWarning: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  max: PropTypes.string,
  min: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  popoverHover: PropTypes.bool,
  popoverIcon: PropTypes.bool,
  popoverText: PropTypes.string,
  popoverTitle: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  value: PropTypes.instanceOf(Date)
};

CustomDay.defaultProps = {
  defaultValue: undefined,
  disablePastWarning: false,
  disabled: false,
  error: false,
  errorText: undefined,
  helperText: undefined,
  id: undefined,
  label: undefined,
  labelProps: {},
  max: undefined,
  min: undefined,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  popoverHover: undefined,
  popoverIcon: false,
  popoverText: undefined,
  popoverTitle: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  value: undefined
};
